


































import { defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import { ModalProgrammatic as modal } from "buefy";
import { isPengelola } from "@/apps/accounts/modules/store";
import { formatRupiah } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "PenarikanListBox",
  props: {
    penarikan: { type: Object, required: true },
  },
  setup(props) {
    const penarikanUrl = require("@/apps/nasabah/assets/img/penarikan.png");

    const showImage = () => {
        return modal.open(
          `<p class="image"><img src="${props.penarikan.mandiri.bukti}"></p>`
        );
    }
    return {
      // Data
      detailUrl: "/penarikan",
      isPengelola,

      // Computed
      penarikanUrl,

      // Method
      formatRupiah,
      showImage,
      toUserDateTimeFormat,
    };
  },
});
